import { graphql } from "gatsby"
import React from "react"
import GenericPage from "../components/layout/genericPage"

const How = ({ data: { wpPage } }) => {
  return <GenericPage data={wpPage}></GenericPage>
}

export default How

export const query = graphql`
  query howQuery {
    wpPage(slug: { eq: "sa-rostar-du" }) {
      title
      content
      pageFields {
        summary
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
